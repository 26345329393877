import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseForm, ModuleKeywords, StandardDto } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { PublishedStandardDataService } from '../../service/data/published-standard-data.service';

@Component({
  selector: 'app-published-standard-item-form',
  templateUrl: './published-standard-item-form.component.html',
  styleUrl: './published-standard-item-form.component.scss',
})
export class PublishedStandardItemFormComponent extends BaseForm<StandardDto> implements OnInit {
  constructor(
    public viewModeService: ViewModeService,
    public policyDataService: PublishedStandardDataService,
    private router: Router
  ) {
    super(viewModeService, ModuleKeywords.PublishedStandard);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    // this.policyBodyForm.patchValue({...data.policyBody});
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      details: new FormControl(null),
      description: new FormControl(null),
      approvers: new FormControl(null, Validators.required),
    });
  }
  // resetFormGroup() {
  //   this.formGroup.reset();
  // }
}
